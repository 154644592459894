import React from 'react';
import { Link } from 'react-router-dom';
import CustomersImg from '../../Images/Customers.svg';
// import AsideImg from '../../Images/customer-aside.svg';
function Customers() {
  return (
    <section className="customers-container">
      <main className='customers'>
        <img src={CustomersImg} alt="" />

        <article>
          <p>Our Customers</p>
          <h3>
            We Provide Secure and Optimum Pension Saving Plan.
          </h3>
          <span className="customers-cta">
            {/* <button>Learn More</button> */}
            <button><Link to="/sign-up">Get Started</Link></button>
          </span>
        </article>
      </main>

      {/* <img src={AsideImg} className="aside-img" alt="" /> */}
    </section>
  );
}

export default Customers;
