import React, { useEffect, useState } from 'react';
import axios from '../../../Components/axios';
import codes from './mobile_countries'
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';

const ViaMobileMoney = ({ user, link, setPaid, reference, id, amount_ }) => {

    const [amount, setAmount] = useState(link.mode === "Custom" ? amount_ ? amount_ : '' : link.amount)
    const [country, setCountry] = useState("ghana")
    const [currency, setCurrency] = useState("GHS")
    const [phone, setPhone] = useState("")
    const [networks, setNetworks] = useState(["MTN", "VODAFONE", "TIGO"])
    const [network, setNetwork] = useState("MTN")
    const [page, setPage] = useState("null")

    const [code, setCode] = useState("")

    const [email, setEmail] = useState("")

    const get_code = () => {

        setPage("loading")

        axios.post('/payments/mobile_money', {
            email: user.email,
            amount,
            reference: reference ? reference : uuidv4(),
            phone: phone,
            network: networks[0] ? network : "",
            order_id: uuidv4(),
            country: country,
            country_code: code,
            currency: currency,
            user_id: user._id,
            fullname: email,
            c_name: code
        })
            .then(res => {

                if (res.data.message === "success") {
                    if (res.data.auth_mode === "redirect") {
                        // setPaid(true)
                        window.open(res.data.authorization.trim(), '_blank').focus()
                    } else {
                        // if (link.redirect_url)
                        //     window.open(`${link.redirect_url}?status=success&reference=${reference}&id=${id ? id : ""}`).focus()
                        setPaid(true)

                    }
                } else {
                    setPage("null")
                    Swal.fire({
                        title: 'Error!',
                        text: 'Payment failed, unable to complete paymment',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })

                }

            })
            .catch(err => {
                setPage("null")
                Swal.fire({
                    title: 'An Error Occured!',
                    text: 'An error occured when processing payment. please try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })

            })

    }

    useEffect(() => {
        setInterval(() => {
            axios.post('/tra-ref', {
                reference
            })
                .then(res => {
                    setPaid(res.data.paid)
                })
                .catch(err => {
                    console.log(err)
                })
        }, 10000)
    }, [setPaid, reference])

    // const paid = () => {
    //     setPaid(true)
    // }
    return (
        <section className="via-card">
            <div className="card-info">
                <h4>Via Mobile Money</h4>
                <p>
                    Choose your mobile money country and network to complete payment
                </p>
                <div className="info-cta-btn">
                    {/* <button>
            <p>Copy</p>
          </button> */}
                    <button onClick={() => {
                        if (amount && phone) {
                            get_code()
                        }
                    }}>Pay Now</button>
                </div>
            </div>

            <div className="transfer-border"></div>

            <form className="card-form">
                <span>
                    <label htmlFor="">Amount ( &#8358; )</label>
                    <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
                </span>

                <div className='expiry-date-cvv'>
                    <span>
                        <label htmlFor="">Country</label>
                        <select onChange={(ev) => {
                            let data = JSON.parse(ev.target.value)
                            setCountry(data.value)
                            setCurrency(data.currency)
                            setCode(data.code)
                            setNetworks(data.network)
                        }} >
                            {codes.map(co => <option value={JSON.stringify(co)}>{co.country}</option>)}
                        </select>
                    </span>

                    {networks[0] && <span>
                        <label htmlFor="">Network</label>
                        <select onChange={(ev) => {
                            setNetwork(ev.target.value)
                        }} >
                            {networks.map(co => <option value={co}>{co}</option>)}
                        </select>
                    </span>}
                </div>

                <span>
                    <label htmlFor="">Phone:</label>
                    <input type="text" onChange={(ev) => setPhone(ev.target.value)} value={phone} placeholder='phone' />
                </span>

                <div className='expiry-date-cvv'>
                    <span>
                        <label htmlFor="">Email:</label>
                        <input type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
                    </span>
                </div>


                {page === "loading" &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto"
                    }} >
                        <ClipLoader
                            color={"#DB831B"}
                            loading={true}
                            // cssOverride={override}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }

                <br />

                {/* <div>
                    <p>Powered By: <span>{user?.payment_options?.mobile_money ? user.payment_options.mobile_money : "Kudipal"}</span></p>
                </div> */}
            </form>
        </section>
    );
};

export default ViaMobileMoney;
