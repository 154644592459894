import React, { useEffect } from 'react';
import './payment-mode.css';
import PaymentSuccessImg from '../../../Images/successful-icon.svg';
// import { Link } from 'react-router-dom';

const PaymentSuccessful = ({ link, reference }) => {
  useEffect(() => {
    const hook = () => link.redirect_url ? window.location = `${link.redirect_url}?status=success&reference=${reference}` : null

    hook()
  }, [link.redirect_url, reference])
  return (
    <section className="apartment-added-modal-container">
      <div className="apartment-added-modal payment-successful-modal">
        <img src={PaymentSuccessImg} alt="" />

        <h4>Thank you <span role="img" aria-label='emoji'>🥳</span>... Payment processing!</h4>

        <p>
          You payment for {link.name} is processing <span role="img" aria-label='emoji' >☺️</span>, an email will be sent to {localStorage.getItem('email')} once payment is confirmed. Thank you for using Kudipal! <span role="img" aria-label='emoji' >🤗</span>
        </p>

        <span className="apartment-added-modal-cta" onClick={() => localStorage.setItem("path", "success")}>
          <a href="https://kudipal.com" className="modal-cta" onClick={() => {
            localStorage.setItem("path", "success")
          }} >
            Close
          </a>
          {/* <Link to="/property-overview" className="modal-cta" onClick={() => {
            localStorage.setItem("path", "success")
          }} >
            View Apartment
          </Link> */}
        </span>
      </div>
    </section>
  );
};

export default PaymentSuccessful;
