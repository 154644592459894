import axios from '../../../Components/axios';
import React, { useEffect, useState } from 'react';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';
import DashboardNavBar from '../../../Components/Dashboard Navbar/DashboardNavBar';

import './dashboard.css';
const Dashboard = () => {

  const [user, setUser] = useState({})
  const [transaction, setTransaction] = useState([])

  const navigate = useNavigate();

  const navigateToAddMoney = () => {
    navigate('/dashboard/add-money');
  };

  const navigateToHistory = () => {
    navigate('/dashboard/transactions')
  }

  const navigateToWithdraw = () => {
    navigate('/dashboard/withdraw')
  }

  useEffect(() => {
    let id = localStorage.getItem("id")

    axios.post('/get-user', {
      user_id: id
    })
      .then(data => {
        setUser(data.data.user_data)
      })
      .catch(err => {
        console.log({
          err
        })
      })

    axios.post('/transactions', {
      user_id: id
    })
      .then(data => {
        setTransaction(data.data.transactions)
      })
      .catch(err => {
        console.log({
          err
        })
      })

  }, [])

  return (
    <section className="dashboard-container">
      <DashboardNavBar />

      <div className="user-name">
        <h4>Hello, {user.first_name}!</h4>
        <button className="user-name-cta" onClick={navigateToAddMoney}>
          <iconify-icon class="iconify" icon="carbon:add"></iconify-icon>
          <p>Add Money</p>
        </button>
      </div>

      <main className="wallet-overview">
        <header className="wallet-header">
          <h6>Account Summary</h6>
        </header>
        <div className="wallet-container">
          <div className="wallet wallet-1">
            <header>
              <p>Total Pension Balance</p>
            </header>

            <h4>&#8358;{user.balance}</h4>

            <span className="wallet-1-cta">
              <button onClick={navigateToAddMoney}>
                <iconify-icon class="iconify" icon="carbon:add"></iconify-icon>
                <p>Add Money</p>
              </button>
              <button onClick={navigateToHistory} >
                <iconify-icon
                  class="iconify"
                  icon="ri:file-history-fill"
                ></iconify-icon>
                <p>History</p>
              </button>
            </span>
          </div>

          <div className="wallet wallet-2">
            <header>
              <p>Available For Withdrawal:</p>
            </header>

            <h4>&#8358;{user.balance * 0.4}</h4>

            <span className="wallet-2-cta">
              <button onClick={() => navigateToWithdraw()}>
                <iconify-icon class="iconify" icon="carbon:add"></iconify-icon>
                <p>Withdraw</p>
              </button>
            </span>
          </div>
        </div>
      </main>

      <footer className="wallet-history">
        <header className="wallet-header">
          <h6>Account History</h6>
        </header>
        <table class="apartments-list">
          <thead>
            <tr>
              <th>Invoice Id</th>
              <th>Mode Of Payment</th>
              <th>Type</th>
              <th>Status</th>
              <th>Amount (&#8358;)</th>
              <th>Date</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {transaction && transaction.map((trx) =>
              <tr>
                <td class="apartment-id payment id">
                  <p>{trx._id}</p>
                </td>

                <td class="name">
                  <p>{trx.name}</p>
                </td>

                <td class="type">
                  <p>{trx.type}</p>
                </td>

                <td class="payment-status">
                  <p>{trx.status}</p>
                </td>

                <td class="date-added">
                  <p>{trx.amount}</p>
                </td>

                <td class="date-added">
                  <p>{trx.createdAt}</p>
                </td>

                {/* <td class="action-options payment-actions">
                  <iconify-icon
                    class="action-icon"
                    icon="carbon:overflow-menu-vertical"
                  ></iconify-icon>
                </td> */}
              </tr>
            )}

          </tbody>
        </table>
      </footer>
    </section>
  );
};

export default Dashboard;
