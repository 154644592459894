import React from 'react'
import './footer-inc.css'
// import MasterCard from '../../images/logos_mastercard.png'
// import VisaCard from './images/logos_visa.png'
// import VerveCard from './images/verve 1.png'
const FooterInc = () => {
  return (
    <div className='footer-inc'>
        <div>
            <p>Payments are verified by paystack</p>

        </div>

        <div>
            <small>©Betatin, INC 2022. All Rights Reserved.</small>
        </div>
    </div>
  )
}


export  default FooterInc;