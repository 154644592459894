import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://test-kudipal-backend.herokuapp.com/merchant/'
  baseURL: "https://kudipal.herokuapp.com/merchant/"
});

instance.interceptors.request.use(
  async (config) => {
    //   if (!config.headers.Authorization) {
    const token = localStorage.getItem("token")
    // console.log("ttt: " + token)
    // console.log(config)
    // if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    // }
    //   }

    return config;
  },
  error => Promise.reject(error)
);

export default instance;