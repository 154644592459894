let codes = [
    {
        bank: "Guaranty Trust Bank",
        code: "737"
    },
    {
        bank: "United Bank of Africa",
        code: "919"
    },
    {
        bank: "Sterling Bank",
        code: "822"
    },
    {
        bank: "Zenith Bank",
        code: "966"
    }
]

export default codes