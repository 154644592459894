import React from 'react';
import MoneyTransferIcon from '../../../Images/money-transfer.svg';
import './add-money.css'
const AddMoneyHeader = ({link}) => {
  return (
    <div className='add-money-header'>
      <h3>Pay for - {link.name}</h3>
      <img src={MoneyTransferIcon} alt="" />
    </div>
  );
};

export default AddMoneyHeader;
