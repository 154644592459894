import axios from '../../../Components/axios';
import React, { useEffect, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2'

const ViaCard = ({ user, link, setPaid, reference, id, amount_ }) => {

  const [amount, setAmount] = useState(link.mode === "Custom" ? amount_ ? amount_ : '' : link.amount)
  const [page, setPage] = useState('Start')

  const [card_number, setCNum] = useState('')
  const [expiry, setExpiry] = useState('/')
  const [cvv, setCvv] = useState('')

  const [pin, setPin] = useState('')
  const [flw, setFlw] = useState('')
  const [otp, setOtp] = useState('')
  const [phone, setPhone] = useState('')

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setCardState] = useState("");
  const [zip, setZip] = useState("");

  const [email, setEmail] = useState("")



  const start_charge = (ev) => {
    ev.preventDefault()

    // window.open(url, '_blank').focus() .parseJSON($("#hiddeniframe").contents().text());

    setPage("loading")

    axios.post('/payments/charge-card', {
      card_number,
      cvv,
      card_expiry_month: expiry.slice(0, expiry.indexOf('/')),
      card_expiry_year: expiry.slice(expiry.indexOf('/') + 1),
      amount,
      email: user.email,
      user_id: user._id,
      reference,
      fullname: email
    })
      .then(resp => {
        // if (resp.data.statusCode === 200) {
        //   setPaid(true);
        // }
        console.log(resp.data)
        if (resp.data.auth_mode === "pin") {
          setPage('Pin')
        } else if (resp.data.auth_mode === "complete") {
          setPaid(true);
        }
        else if (resp.data.auth_mode === "redirect") {
          // setPage('Redirect')
          // setPaid(true)
          window.open(resp.data.authorization.redirect.trim(), '_blank').focus()
          // setRedirect(resp.data.authorization.redirect.trim())
        } else if (resp.data.auth_mode === "avs_noauth") {
          setPage('No Auth')
        }
        // else if (resp.data.transaction) {
        // } 
        else {
          Swal.fire({
            title: 'An Error Occured!',
            text: 'This card could not be charged at the moment, please check with your bank or try another card',
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }

      })
      .catch(err => {
        console.log(err)
        Swal.fire({
          title: 'An Error Occured!',
          text: 'This card could not be charged at the moment, please check with your bank or try another card',
          icon: 'error',
          confirmButtonText: 'Close'
        })
      })
  }

  const charge_pin = (ev) => {

    ev.preventDefault()
    setPage("loading")

    axios.post('/payments/card-pin', {
      card_number,
      cvv,
      card_expiry_month: expiry.slice(0, expiry.indexOf('/')),
      card_expiry_year: expiry.slice(expiry.indexOf('/') + 1),
      amount,
      email: user.email,
      user_id: user._id,
      pin,
      reference,
      fullname: email
    })
      .then(data => {

        if (data.data.processor_response === 'Kindly enter the mobile no registered with your bank') {
          setPage('Phone')
        } else {

          if (data.data.message === "otp required") {
            // setFLW(data.data.flw_ref);
            setFlw(data.data.flw_ref)
            setPage('OTP')
            localStorage.setItem("flw", data.data.flw_ref);
          }

        }

      })
      .catch(err => {
        console.log(err)
      })

  }

  const charge_pin_phone = (ev) => {

    ev.preventDefault()
    setPage("loading")

    axios.post('/payments/card-pin-phone', {
      card_number,
      cvv,
      card_expiry_month: expiry.slice(0, expiry.indexOf('/')),
      card_expiry_year: expiry.slice(expiry.indexOf('/') + 1),
      amount,
      email: user.email,
      user_id: user._id,
      pin,
      phone,
      reference,
      fullname: email
    })
      .then(data => {

        if (data.data.message === "otp required") {
          // setFLW(data.data.flw_ref);
          setFlw(data.data.flw_ref)
          setPage('OTP')
          localStorage.setItem("flw", data.data.flw_ref);
        }

      })
      .catch(err => {
        console.log(err)
      })

  }

  const charge_otp = (ev) => {

    ev.preventDefault()
    setPage("loading")

    axios.post('/payments/card-otp', {
      otp: otp,
      flw_ref: flw,
      user_id: user._id,
    })
      .then(fianl => {

        if (fianl.data.message === "success") {
          // if (link.redirect_url)
            // window.open(`${link.redirect_url}?status=success&reference=${reference}&id=${id ? id : ""}`).focus()
          setPaid(true);

        } else {
          setPage('OTP')
          Swal.fire({
            title: 'An Error Occured!',
            text: 'This card could not be charged at the moment, please check OTP or try another card',
            icon: 'error',
            confirmButtonText: 'Close'
          })
        }

      })
      .catch(err => {
        setPage('OTP')
        Swal.fire({
          title: 'An Error Occured!',
          text: 'This card could not be charged at the moment, please check OTP or try another card',
          icon: 'error',
          confirmButtonText: 'Close'
        })
        console.log(err)
      })

  }

  const no_auth = (ev) => {

    ev.preventDefault()

    setPage("loading")

    axios.post('/payments/card-no-auth', {
      card_number,
      cvv,
      card_expiry_month: expiry.slice(0, expiry.indexOf('/')),
      card_expiry_year: expiry.slice(expiry.indexOf('/') + 1),
      amount,
      email: user.email,
      user_id: user._id,
      address,
      city,
      country,
      state,
      zipcode: zip,
      reference,
      fullname: email
    })
      .then(resp => {

        if (resp.data.auth_mode === "pin") {
          setPage('Pin')
        } else if (resp.data.auth_mode === "redirect") {
          // setPage('Redirect')
          setPaid(true)
          window.open(resp.data.authorization.redirect.trim(), '_blank').focus()
          // setRedirect(resp.data.authorization.redirect.trim())
        } else if (resp.data.auth_mode === "avs_noauth") {
          setPage('No Auth')
        } else if (resp.data.transaction) {
        }

      })
      .catch(err => {
        console.log(err)
      })
  }

  // const paid = () => {
  //   setPaid(true)
  // }

  useEffect(() => {
    setInterval(() => {
      axios.post('/tra-ref', {
        reference
      })
        .then(res => {
          setPaid(res.data.paid)
        })
        .catch(err => {
          console.log(err)
        })
    }, 10000)
  }, [setPaid, reference])

  return (
    <section className="via-card md:flex-row flex-col">
      <div className="card-info">
        <h4 className='mt-10 md:mt-0'>Via Card</h4>
        <p>
          Pay with card, input your card details to complete the transactions.
        </p>
        <div className="info-cta-btn">
          {/* <button>
            <p>Copy</p>
          </button> */}
          {page === "Start" && <button onClick={(ev) => {
            start_charge(ev)
          }}>Pay</button>}
        </div>
      </div>

      <div className="transfer-border md;flex hidden"></div>

      <form className="card-form md:mt-10">
        <span>
          <label htmlFor="">Amount ( &#8358; )</label>
          <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
        </span>

        {page === "Start" &&
          <>
            <span>
              <label htmlFor="">Email:</label>
              <input type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
            </span>

            <span>
              <label htmlFor="">Card Number</label>
              <input type="text" placeholder='1234 1234 1234 1234' value={card_number} onChange={(ev) => setCNum(ev.target.value.trim())} />
            </span>
            <div className='expiry-date-cvv'>
              <span>
                <label htmlFor="">Card Expiry Date</label>
                <input type='text' placeholder='07/25' value={expiry} onChange={(ev) => setExpiry(ev.target.value.trim())} />
              </span>
              <span>
                <label htmlFor="">CVV</label>
                <input type="password" placeholder='123' value={cvv} onChange={(ev) => setCvv(ev.target.value.trim())} />
              </span>
            </div>
          </>
        }
        {page === "Pin" &&
          <>
            <span>
              <label htmlFor="">Enter Card Pin</label>
              <input type="password" placeholder='1234' value={pin} onChange={(ev) => setPin(ev.target.value.trim())} />
            </span>
            <div className="info-cta-btn">
              <button onClick={(ev) => {
                charge_pin(ev)
              }}>Send</button>
            </div>

            {/* <div className='expiry-date-cvv'>
              <span>
                <label htmlFor="">Card Expiry Date</label>
                <input type='text' placeholder='07/25' value={expiry} onChange={(ev) => setExpiry(ev.target.value.trim())} />
              </span>
              <span>
                <label htmlFor="">CVV</label>
                <input type="pin" placeholder='123' value={cvv} onChange={(ev) => setCvv(ev.target.value.trim())} />
              </span>
            </div> */}
          </>
        }

        {page === "Phone" &&
          <>
            <span>
              <label htmlFor="">Enter Phone number assocaited with bank</label>
              <input type="phone" placeholder='09000000000' value={phone} onChange={(ev) => setPhone(ev.target.value.trim())} />
            </span>
            <div className="info-cta-btn">
              <button onClick={(ev) => {
                charge_pin_phone(ev)
              }}>Send</button>
            </div>

            {/* <div className='expiry-date-cvv'>
              <span>
                <label htmlFor="">Card Expiry Date</label>
                <input type='text' placeholder='07/25' value={expiry} onChange={(ev) => setExpiry(ev.target.value.trim())} />
              </span>
              <span>
                <label htmlFor="">CVV</label>
                <input type="pin" placeholder='123' value={cvv} onChange={(ev) => setCvv(ev.target.value.trim())} />
              </span>
            </div> */}
          </>
        }

        {page === "OTP" &&
          <>
            <span>
              <label htmlFor="">Enter OTP sent to your phone number</label>
              <input type="text" placeholder='Enter OTP' value={otp} onChange={(ev) => setOtp(ev.target.value.trim())} />
            </span>
            <div className="info-cta-btn">
              <button onClick={(ev) => {
                charge_otp(ev)
              }}>Confirm</button>
            </div>
            {/* <div className='expiry-date-cvv'>
              <span>
                <label htmlFor="">Card Expiry Date</label>
                <input type='text' placeholder='07/25' value={expiry} onChange={(ev) => setExpiry(ev.target.value.trim())} />
              </span>
              <span>
                <label htmlFor="">CVV</label>
                <input type="pin" placeholder='123' value={cvv} onChange={(ev) => setCvv(ev.target.value.trim())} />
              </span>
            </div> */}
          </>
        }
        {page === "No Auth" &&
          <>
            <span>
              <label htmlFor="">Address</label>
              <input type="text" placeholder='address' value={address} onChange={(ev) => setAddress(ev.target.value.trim())} />
            </span>
            <div className='expiry-date-cvv'>

              <span>
                <label htmlFor="">City</label>
                <input type='text' placeholder='city' value={city} onChange={(ev) => setCity(ev.target.value.trim())} />
              </span>

              <span>
                <label htmlFor="">State</label>
                <input type="text" placeholder='State' value={state} onChange={(ev) => setCardState(ev.target.value.trim())} />
              </span>

            </div>

            <div className='expiry-date-cvv'>

              <span>
                <label htmlFor="">Country</label>
                <input type='text' placeholder='Country' value={country} onChange={(ev) => setCountry(ev.target.value.trim())} />
              </span>

              <span>
                <label htmlFor="">Zip</label>
                <input type="pin" placeholder='Zipcode' value={zip} onChange={(ev) => setZip(ev.target.value.trim())} />
              </span>

            </div>

            <div className="info-cta-btn">
              <button onClick={(ev) => {
                no_auth(ev)
              }}>Pay</button>
            </div>

          </>
        }
        {page === "loading" &&
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto"
          }} >
            <ClipLoader
              color={"#DB831B"}
              loading={true}
              // cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }

        {/* {page === "Redirect" &&
          <>
            <iframe title='3Ds' src={redirect} style={{
              height: "500px",
              width: "500px",
              backgroundColor: "white",
            }} ></iframe>
          </>
        } */}

        {/* <div>
          <p className='mb-10 md:mb-10'>Powered By: <span>{user?.payment_options?.card ? user.payment_options.card : "Kudipal"}</span></p>
        </div> */}

      </form>
    </section>
  );
};

export default ViaCard;
