import React from "react";
import Lottie from "lottie-react";
import hourglass from "../../animations/hourglass.json";
import { MyTimer } from "../timer";
// import { useNavigate } from "react-router-dom";

export default function PendingModal({ setModal }) {
  // const navigate = useNavigate();
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 10 minutes timer
  return (
    <div className="min-w-full min-h-full absolute top-0 left-0 flex flex-col items-center justify-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      {" "}
      <Lottie className="w-40 h-40" animationData={hourglass} loop={true} />
      <p children={"Please wait a moment, we are confirming and processing your payment..."} />
      <div className="self-center mt-5">
        <MyTimer expiryTimestamp={time} handleFunction={() => setModal(false)} />
      </div>
    </div>
  );
}
