import React, { useState } from 'react'
import axios from '../../../Components/axios';
import './add-money.css'
import ClipLoader from "react-spinners/ClipLoader";

const ViaMPGS = ({ user, link, setPaid, reference }) => {

    const [amount, setAmount] = useState(link.mode === "Custom" ? '' : link.amount)
    const [email, setEmail] = useState('')

    const [page, setPage] = useState("null")

    const get_account = () => {

        setPage('loading')
        axios.post('/payments/mpgs/create-session', {
            amount,
            link: link._id
        })
            .then(res => {
                console.log(res.data)
                setPage('null')
                localStorage.setItem('session_id', res.data.data)
                localStorage.setItem('link_id', link._id)
                localStorage.setItem('amount', amount)
                localStorage.setItem('id', res.data.order)
                localStorage.setItem('customer_email', email)
                localStorage.setItem('description', link.name)
                localStorage.setItem('show', 'active')
                window.location.reload()
                // window.checkout = function (data) {
                //     console.log(data)
                // }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // const paid = () => {
    //     setPaid(true)
    // }

    return (
        <section className='via-transfer'>
            <div className='transfer-info'>
                <h4>Via MasterCard Payment Gateway</h4>
                <p>
                    Pay with card, put in your card details to complete payment.
                </p>
                <div className="info-cta-btn">

                    <button onClick={() => {
                        if (amount) {
                            get_account()
                        }
                    }}>Get Account</button>

                </div>
            </div>

            <div className='transfer-border'></div>

            <div className='transfer-details'>
                <span>
                    <label htmlFor="">Amount ( &#8358; )</label>
                    <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
                </span>

                <span>
                    <label htmlFor="Account Number :">Email</label>
                    <input type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
                </span>

                {page === "loading" &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto"
                    }} >
                        <ClipLoader
                            color={"#DB831B"}
                            loading={true}
                            // cssOverride={override}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }


                {/* <div>
                    <p>Powered By: <span>{user.payment_options.ussd ? user.payment_options.mpgs : "Kudipal"}</span></p>
                </div> */}

            </div>


        </section>
    )
}

export default ViaMPGS