import React, { useEffect, useState } from 'react'
import axios from '../../../Components/axios';
import './add-money.css'
import ClipLoader from "react-spinners/ClipLoader";

const ViaBankTransfer = ({ user, link, setPaid, setModal, reference, id, amount_ }) => {

    const [amount, setAmount] = useState(link.mode === "Custom" ? amount_ ? amount_ : '' : link.amount)
    const [account, setAccount] = useState()
    const [page, setPage] = useState("null")
    const [email, setEmail] = useState("")

    const get_account = () => {

        setPage("loading")

        axios.post('/payments/bank-transfer', {
            email: user.email,
            amount,
            is_permanent: false,
            user_id: user._id,
            fullname: email,
            reference
        })
            .then(code => {
                // console.log(code.data)
                setAccount(code.data)
                setPage("null")
            })
            .catch(err => {

            })

    }

    const paid = () => {
        // if (link.redirect_url)
        //     window.open(`${link.redirect_url}?status=success&reference=${reference}&id=${id ? id : ""}`).focus()
        setModal(true)
        axios.post('/tra-ref', {
            reference
        })
            .then(res => {
                setPaid(res.data.paid)
                setPage("null")
            })
            .catch(err => {
                console.log(err)
                setPage("null")
            })

    }

    useEffect(() => {
        setInterval(() => {
            axios.post('/tra-ref', {
                reference
            })
                .then(res => {
                    setPaid(res.data.paid)
                })
                .catch(err => {
                    console.log(err)
                })
        }, 10000)
    }, [setPaid, reference])

    return (
        <section className='via-transfer md:flex-row flex-col'>
            <div className='transfer-info'>
                <h4 className='mt-10 md:mt-0'>Via Bank Transfer</h4>
                <p>
                    Pay with bank transfer, get account number to pay to.
                </p>
                <div className="info-cta-btn">

                    <button onClick={() => {
                        if (amount) {
                            get_account()
                        }
                    }}>Get Account</button>
                    {account && account.account_number && <button onClick={() => navigator.clipboard.writeText(`Account Number: ${account.account_number} \n Bank: ${account.bank_name}`)}>
                        Copy All
                    </button>}
                </div>
            </div>

            <div className='transfer-border md:flex hidden'></div>

            <div className='transfer-details'>
                <span>
                    <label htmlFor="Account Number :">Amount ( &#8358; )</label>
                    <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
                </span>

                {account && account.account_number ? <>
                    <span>
                        <label htmlFor="Account Number :">Account Number</label>
                        <p>{account.account_number}</p>
                    </span>
                    <span>
                        <label htmlFor="Account Number :">Bank</label>
                        <p>{account.bank_name}</p>
                    </span>
                    <span>
                        <label htmlFor="Bank Nane :">Note</label>
                        <p>{account.note ? account.note : `Transfer only N${amount}`}</p>
                    </span>

                    <div className="info-cta-btn">
                        <button onClick={() => paid()}>Paid</button>
                    </div>

                </>
                    :
                    <span>
                        <label htmlFor="Account Number :">Email</label>
                        <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
                    </span>
                }

                {page === "loading" &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto"
                    }} >
                        <ClipLoader
                            color={"#DB831B"}
                            loading={true}
                            // cssOverride={override}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }

                {/* <div>
                    <p className='mb-10 md:mb-0'>Powered By: <span>{user?.payment_options?.bank_transfer ? user.payment_options.bank_transfer : "Kudipal"}</span></p>
                </div> */}

            </div>


        </section>
    )
}

export default ViaBankTransfer