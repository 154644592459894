import { useTimer } from "react-timer-hook";
import { useNavigate } from "react-router-dom";


export function MyTimer({ expiryTimestamp, handleFunction }) {
  const navigate = useNavigate();

    const {
      // totalSeconds,
      seconds,
      minutes,
      hours,
      days,
      // isRunning,
      // start,
      // pause,
      // resume,
      // restart,
    } = useTimer({
      expiryTimestamp,
      onExpire: () => navigate('/login'),
    });
  
    return (
      <div style={{ textAlign: "center" }}>
        {/* <h1>react-timer-hook </h1>
        <p>Timer Demo</p> */}
        <div className="bg-primary text-white p-5 rounded-2xl text-base">
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:
          <span>{seconds}</span>
        </div>
        {/* <p>{isRunning ? "Running" : "Not running"}</p>
        <button onClick={start}>Start</button>
        <button onClick={pause}>Pause</button>
        <button onClick={resume}>Resume</button>
        <button
          onClick={() => {
            // Restarts to 5 minutes timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + 300);
            restart(time);
          }}
        >
          Restart
        </button> */}
      </div>
    );
  }