import React, { useState } from 'react';
import axios from '../../../Components/axios';
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';

const ViaMomo = ({ user, link, setPaid, reference, id, amount_ }) => {

    const [amount, setAmount] = useState(link.mode === "Custom" ? amount_ ? amount_ : '' : link.amount)

    const [phone, setPhone] = useState("")

    const [page, setPage] = useState("null")


    const [email, setEmail] = useState("")

    const get_code = () => {

        setPage("loading")

        axios.post('/payments/momo', {
            email: user.email,
            amount,
            reference: reference ? reference : uuidv4(),
            phone: phone,
            currency: "EUR",
            user_id: user._id,
            fullname: email
        })
            .then(res => {

                if (res.data.message === "success") {
                    // if (res.data.auth_mode === "redirect") {
                    // if (link.redirect_url)
                    //     window.open(`${link.redirect_url}?status=success&reference=${reference}&id=${id ? id : ""}`).focus()
                    setPaid(true)

                    // window.open(res.data.authorization.trim(), '_blank').focus()
                    // } else {
                    //     setPaid(true)
                    // }
                } else {
                    setPage("null")
                    Swal.fire({
                        title: 'Error!',
                        text: 'Payment failed, unable to complete paymment',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })

                }

            })
            .catch(err => {
                setPage("null")
                Swal.fire({
                    title: 'An Error Occured!',
                    text: 'An error occured when processing payment. please try again',
                    icon: 'error',
                    confirmButtonText: 'Close'
                })

            })

    }

    // const paid = () => {
    //     setPaid(true)
    // }
    return (
        <section className="via-card">
            <div className="card-info">
                <h4>Via Momo Mobile Money</h4>
                <p>
                    Customers are able to make payment through their momo
                </p>
                <div className="info-cta-btn">
                    {/* <button>
            <p>Copy</p>
          </button> */}
                    <button onClick={() => {
                        if (amount && phone) {
                            get_code()
                        }
                    }}>Pay Now</button>
                </div>
            </div>

            <div className="transfer-border"></div>

            <form className="card-form">
                <span>
                    <label htmlFor="">Amount ( &#8358; )</label>
                    <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
                </span>

                <span>
                    <label htmlFor="">Phone:</label>
                    <input type="text" onChange={(ev) => setPhone(ev.target.value)} value={phone} placeholder='phone' />
                </span>

                <div className='expiry-date-cvv'>
                    <span>
                        <label htmlFor="">Email:</label>
                        <input type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
                    </span>
                </div>


                {page === "loading" &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto"
                    }} >
                        <ClipLoader
                            color={"#DB831B"}
                            loading={true}
                            // cssOverride={override}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }

                <br />

                {/* <div>
                    <p>Powered By: <span>{user?.payment_options?.momo ? user.payment_options.momo : "Kudipal"}</span></p>
                </div> */}
            </form>
        </section>
    );
};

export default ViaMomo;
