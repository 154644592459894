let codes = [
    {
        country: "Ghana",
        value: "ghana",
        currency: "GHS",
        code: "GHA",
        network: ["MTN", "VODAFONE", "TIGO"]
    },
    {
        country: "Nigeria",
        value: "nigeria",
        currency: "NGN",
        code: "NGA",
        network: ["MTN", "AIRTEL"]
    },
    {
        country: "Uganda",
        value: "uganda",
        currency: "UGX",
        code: "UGA",
        network: ["MTN", "AIRTEL"]
    },
    {
        country: "Rwanda",
        value: "rwanda",
        currency: "RWF",
        code: "RWA",
        network: []
    },
    {
        country: "Zambia",
        value: "zambia",
        currency: "ZMW",
        code: "ZMB",
        network: ["MTN", "ZAMTEL"]
    },

    {
        country: "Cameroon",
        value: "franco",
        currency: "XAF",
        code: "CMR",
        network: []
    },
    {
        country: "Senegal",
        value: "franco",
        currency: "XAF",
        code: "SEN",
        network: []
    },
    {
        country: "Burkina Faso",
        value: "franco",
        currency: "XAF",
        code: "BFA",
        network: []
    },
    {
        country: "Côte d'Ivoire",
        value: "franco",
        currency: "XAF",
        code: "CIV",
        network: []
    }
]

export default codes