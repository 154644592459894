let codes = [
    {
        bank: "Access Bank",
        code: "044"
    },
    {
        bank: "First Bank",
        code: "011"
    },
    {
        bank: "Guaranty Trust Bank",
        code: "058"
    },
    {
        bank: "Sterling Bank",
        code: "232"
    },
    {
        bank: "United Bank For Africa",
        code: "033"
    },
    {
        bank: "Zenith Bank",
        code: "057"
    }
]

export default codes