import React, { useEffect, useState } from "react";
import DashboardNavBar from "../../../Components/Dashboard Navbar/DashboardNavBar";
import AddMoneyHeader from "./AddMoneyHeader";
import ViaBankTransfer from "./ViaBankTransfer";
import ViaCard from "./ViaCard";
// import { useNavigate } from 'react-router-dom';
import "./add-money.css";
import axios from "../../../Components/axios";
import ViaUssd from "./ViaUssd";
import { useParams, useSearchParams } from "react-router-dom";
import PaymentSuccessful from "./PaymentSuccessful";
import ClipLoader from "react-spinners/ClipLoader";

import { Icon } from "@iconify/react";
import ViaMPGS from "./ViaMPGS";
import ViaQrCode from "./ViaQrcode";
import ViaDirectDebit from "./ViaDirectDebit";
import ViaMobileMoney from "./ViaMobileMoney";
import ViaMpesa from "./ViaMpesa";
import ViaMomo from "./ViaMomo";
import PendingModal from "../../../Components/modal/pendingmodal";

const AddMoneyContainer = () => {
  const [link, setLink] = useState({});
  const [user, setUser] = useState({});
  const [paid, setPaid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState("null");
  const [searchParams, setSearchParams] = useSearchParams();
  const [reference, setRef] = useState("");
  const [pub_key, setPub] = useState("");
  const [amount, setAmount] = useState("");
  const [modal, setModal] = useState(false)

  const routeParams = useParams();

  // const navigate = useNavigate();

  // const navigateToAddMoney = () => {
  //   navigate('/dashboard');
  // };

  useEffect(() => {
    let id = routeParams.id;
    setLoading(true);

    setSearchParams(searchParams);

    setPub(searchParams.get("pub_key"));
    setRef(searchParams.get("reference"));
    setAmount(searchParams.get("amount"));

    axios
      .post("/payments/get-one-link", {
        link_id: id,
      })
      .then((data) => {
        // console.log(data.data)
        setLink(data.data.link_data);

        axios
          .post("/get-pay-user", {
            id: data.data.link_data.user_id,
          })
          .then((res) => {
            setLoading(false);
            setUser(res.data.user);
          })
          .catch((err) => {
            setLoading(false);
            alert("Error fetching data", err.message);
            console.log({
              err,
            });
          });
      })
      .catch((err) => {
        console.log({
          err,
        });
      });
  }, [routeParams.id, searchParams, setSearchParams]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <section className="dashboard-container">
      {paid ? (
        <PaymentSuccessful
          link={link}
          reference={reference}
          pub_key={pub_key}
        />
      ) : (
        <>
          {modal && <PendingModal setModal={setModal} />}
          <DashboardNavBar />

          <AddMoneyHeader link={link} />
          <p className="text-center">Hi, Please Choose any of the payment options provided below</p>

          <div className="flex md:flex-row w-full items-center justify-evenly mt-20">
            {link.name && link.options.includes("transfer") && (
              <div
                className={
                  option === "transfer"
                    ? "w-[20%] flex flex-col md:flex-row items-center justify-center bg-active rounded-lg h-[75px]"
                    : "w-[20%] flex flex-col md:flex-row items-center justify-center bg-primary rounded-lg h-[75px]"
                }
                onClick={() => setOption("transfer")}
              >
                <span className="text-xl md:text-4xl md:mr-5">
                  <Icon icon="clarity:bank-solid" />
                </span>
                <p className="text-xs text-center md:text-base">
                  Bank Transfer
                </p>
              </div>
            )}

            {link.name && link.options.includes("card") && (
              <div
                className={
                  option === "card"
                    ? "w-[20%] flex flex-col md:flex-row items-center justify-center bg-active rounded-lg h-[75px]"
                    : "w-[20%] flex flex-col md:flex-row items-center justify-center bg-primary rounded-lg h-[75px]"
                }
                onClick={() => setOption("card")}
              >
                <span className="text-xl md:text-4xl md:mr-5">
                  <Icon icon="wpf:bank-cards" />
                </span>
                <p className="text-xs text-center md:text-base">Card</p>
              </div>
            )}

            {link.name && link.options.includes("ussd") && (
              <div
                className={
                  option === "ussd"
                    ? "w-[20%] flex flex-col md:flex-row items-center justify-center bg-active rounded-lg h-[75px]"
                    : "w-[20%] flex flex-col md:flex-row items-center justify-center bg-primary rounded-lg h-[75px]"
                }
                onClick={() => setOption("ussd")}
              >
                <span className="text-xl md:text-4xl md:mr-5">
                  <Icon icon="ic:twotone-numbers" />
                </span>
                <p className="text-xs text-center md:text-base">USSD</p>
              </div>
            )}

            {/* {link.name && link.options.includes('card') &&
            <div className={option === 'mpgs' ? 'options-active' : 'options'} onClick={() => setOption('mpgs')}>
              <span className='option-icon'>
                <Icon icon="wpf:bank-cards" />
              </span>
              <p className='option-name'>MasterCard</p>
            </div>
          } */}

            {link.name && link.options.includes("qrcode") && (
              <div
                className={option === "qrcode" ? "options-active" : "options"}
                onClick={() => setOption("qrcode")}
              >
                <span className="option-icon">
                  <Icon icon="wpf:bank-cards" />
                </span>
                <p className="option-name">Qr Code</p>
              </div>
            )}

            {link.name && link.options.includes("mobile_money") && (
              <div
                className={
                  option === "mobile_money" ? "options-active" : "options"
                }
                onClick={() => setOption("mobile_money")}
              >
                <span className="option-icon">
                  <Icon icon="wpf:bank-cards" />
                </span>
                <p className="option-name">Mobile Money</p>
              </div>
            )}

            {link.name && link.options.includes("mobile_money") && (
              <div
                className={
                  option === "mobile_money momo" ? "options-active" : "options"
                }
                onClick={() => setOption("mobile_money momo")}
              >
                <span className="option-icon">
                  <Icon icon="wpf:bank-cards" />
                </span>
                <p className="option-name">Mobile Money - Momo</p>
              </div>
            )}

            {link.name && link.options.includes("mpesa") && (
              <div
                className={option === "mpesa" ? "options-active" : "options"}
                onClick={() => setOption("mpesa")}
              >
                <span className="option-icon">
                  <Icon icon="wpf:bank-cards" />
                </span>
                <p className="option-name">Mpesa</p>
              </div>
            )}

            {/* {link.name && link.options.includes('card') &&
            <div className={option === 'direct-debit' ? 'options-active' : 'options'} onClick={() => setOption('direct-debit')}>
              <span className='option-icon'>
                <Icon icon="wpf:bank-cards" />
              </span>
              <p className='option-name'>Bank Debit</p>
            </div>
          } */}
          </div>

          {option === "transfer" && (
            <ViaBankTransfer
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "card" && (
            <ViaCard
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "ussd" && (
            <ViaUssd
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "mpgs" && (
            <ViaMPGS
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "qrcode" && (
            <ViaQrCode
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "direct-debit" && (
            <ViaDirectDebit
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "mobile_money" && (
            <ViaMobileMoney
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "mpesa" && (
            <ViaMpesa
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
          {option === "mobile_money momo" && (
            <ViaMomo
              user={user}
              link={link}
              setPaid={setPaid}
              setModal={setModal}
              reference={reference}
              pub_key={pub_key}
              amount_={amount}
            />
          )}
        </>
      )}
    </section>
  );
};

export default AddMoneyContainer;
