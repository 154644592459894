import axios from '../../../Components/axios';
import React, { useState } from 'react';
import codes from './bank_codes';
import ClipLoader from "react-spinners/ClipLoader";

const ViaDirectDebit = ({ user, link, setPaid, reference, id, amount_ }) => {

  const [amount, setAmount] = useState(link.mode === "Custom" ? amount_ ? amount_ : '' : link.amount)
  const [page, setPage] = useState('Start')
  const [code, setCode] = useState("044")
  const [account, setAccount] = useState("")
  const [account_name, setAccName] = useState("")

  const [otp, setOtp] = useState("")
  const [flw, setFlw] = useState("")


  const [email, setEmail] = useState("")

  const bank = (ev) => {
    let bank_data = ev;
    setCode(ev)

    if (account.length === 10) {
      setPage("loading")

      axios
        .post('/get-account-name', {
          account_number: account,
          bank_code: ev,
        })
        .then((acc) => {
          setPage("Start")
          console.log(acc.data);
          setAccName(acc.data.account_name);
        })
        .catch((err) => {
          setPage("Start")
          alert(err);
        });
    }

    console.log(bank_data);
  };

  const acc_num = (v) => {
    setAccount(v);

    if (v.length === 10) {
      setPage("loading")
      axios
        .post('/get-account-name', {
          account_number: v,
          bank_code: code,
        })
        .then((acc) => {
          setPage("Start")
          console.log(acc.data);
          setAccName(acc.data.account_name);
        })
        .catch((err) => {
          setPage("Start")
          alert(err);
        });
    }
  };

  const start_charge = (ev) => {
    ev.preventDefault()

    // window.open(url, '_blank').focus() .parseJSON($("#hiddeniframe").contents().text());

    setPage("loading")

    axios.post('/payments/direct-debit', {
      bank_code: code,
      account_number: account,
      amount,
      email: user.email,
      user_id: user._id,
      fullname: email
    })
      .then(resp => {

        console.log(resp.data)
        if (resp.data.auth_mode === "otp") {
          setFlw(resp.data.flw_ref)
          setPage('OTP')
          localStorage.setItem("flw", resp.data.flw_ref);
        } else if (resp.data.auth_mode === "redirect") {
          // setPage('Redirect')

          window.open(resp.data.authorization.redirect.trim(), '_blank').focus()
          setPaid(true)
          // setRedirect(resp.data.authorization.redirect.trim())
        }

      })
      .catch(err => {
        console.log(err)
      })
  }

  const charge_otp = (ev) => {

    ev.preventDefault()
    setPage("loading")

    axios.post('/payments/direct-otp', {
      otp: otp,
      flw_ref: flw,
      user_id: user._id,
    })
      .then(fianl => {

        if (fianl.data.message === "success") {
          // if (link.redirect_url)
          //   window.open(`${link.redirect_url}?status=success&reference=${reference}&id=${id ? id : ""}`).focus()
          setPaid(true);

        } else {
          alert("Failed, an error occured. Please try again");
        }

      })
      .catch(err => {
        console.log(err)
      })

  }

  return (
    <section className="via-card">
      <div className="card-info">
        <h4>Via Direct Debit</h4>
        <p>
          Pay through direct bank debit.
        </p>
        <div className="info-cta-btn">
          {/* <button>
            <p>Copy</p>
          </button> */}
          {page === "Start" && <button onClick={(ev) => {
            start_charge(ev)
          }}>Pay</button>}
        </div>
      </div>

      <div className="transfer-border"></div>

      <form className="card-form">
        <span>
          <label htmlFor="">Amount ( &#8358; )</label>
          <input className='border-neutral-600 bg-transparent' type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
        </span>
        {page === "Start" &&
          <>
            <span>
              <label htmlFor="">Email:</label>
              <input type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
            </span>

            <span>
              <label htmlFor="">Bank:</label>
              <select onChange={(ev) => {
                bank(ev.target.value)
              }} >
                {codes.map(co => <option value={co.code}>{co.bank}</option>)}
              </select>
            </span>

            <span>
              <label htmlFor="">Account Number</label>
              <input type="text" onChange={(ev) => acc_num(ev.target.value)} value={account} placeholder='0123456789' />
            </span>

            <span>
              <label htmlFor="">Account Name</label>
              <input type="text" value={account_name} placeholder='Auto fill' />
            </span>
          </>
        }

        {page === "OTP" &&
          <>
            <span>
              <label htmlFor="">Enter OTP sent to your phone number</label>
              <input type="text" placeholder='Enter OTP' value={otp} onChange={(ev) => setOtp(ev.target.value.trim())} />
            </span>
            <div className="info-cta-btn">
              <button onClick={(ev) => {
                charge_otp(ev)
              }}>Confirm</button>
            </div>
            {/* <div className='expiry-date-cvv'>
              <span>
                <label htmlFor="">Card Expiry Date</label>
                <input type='text' placeholder='07/25' value={expiry} onChange={(ev) => setExpiry(ev.target.value.trim())} />
              </span>
              <span>
                <label htmlFor="">CVV</label>
                <input type="pin" placeholder='123' value={cvv} onChange={(ev) => setCvv(ev.target.value.trim())} />
              </span>
            </div> */}
          </>
        }

        {page === "loading" &&
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto"
          }} >
            <ClipLoader
              color={"#DB831B"}
              loading={true}
              // cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }

        {/* {page === "Redirect" &&
          <>
            <iframe title='3Ds' src={redirect} style={{
              height: "500px",
              width: "500px",
              backgroundColor: "white",
            }} ></iframe>
          </>
        } */}

        {/* <div>
          <p>Powered By: <span>{user?.payment_options?.bank_debit ? user.payment_options.bank_debit : "Kudipal"}</span></p>
        </div> */}

      </form>
    </section>
  );
};

export default ViaDirectDebit;
