import React, { useEffect, useState } from 'react';
import axios from '../../../Components/axios';
import codes from './ussd_codes'
import ClipLoader from "react-spinners/ClipLoader";

const ViaUssd = ({ user, link, setPaid, setModal, reference, id, amount_ }) => {

    const [amount, setAmount] = useState(link.mode === "Custom" ? amount_ ? amount_ : '' : link.amount)
    const [code, setCode] = useState("737")
    const [ussd, setUssd] = useState("")
    const [page, setPage] = useState("null")

    const [email, setEmail] = useState("")

    const get_code = () => {

        setPage("loading")

        axios.post('/payments/ussd', {
            email: user.email,
            amount,
            code: code,
            user_id: user._id,
            reference,
            fullname: email
        })
            .then(code => {
                // console.log(code.data)
                setPage("null")
                setUssd(code.data.code)
            })
            .catch(err => {

            })

    }

    const paid = () => {
        // if (link.redirect_url)
        //     window.open(`${link.redirect_url}?status=success&reference=${reference}&id=${id ? id : ""}`).focus()
        setModal(true)
        axios.post('/tra-ref', {
            reference
        })
            .then(res => {
                setPaid(res.data.paid)
                setPage("null")
            })
            .catch(err => {
                console.log(err)
                setPage("null")
            })
    }

    useEffect(() => {
        setInterval(() => {
            axios.post('/tra-ref', {
                reference
            })
                .then(res => {
                    setPaid(res.data.paid)
                })
                .catch(err => {
                    console.log(err)
                })
        }, 10000)
    }, [setPaid, reference])

    return (
        <section className="via-card md:flex-row flex-col">
            <div className="card-info">
                <h4 className='mt-10 md:mt-0'>Via USSD</h4>
                <p>
                    Pay through USSD, select your prefered bank and pay.
                </p>
                <div className="info-cta-btn">
                    {/* <button>
            <p>Copy</p>
          </button> */}
                    <button onClick={() => {
                        if (amount) {
                            get_code()
                        }
                    }}>Get Code</button>
                </div>
            </div>

            <div className="transfer-border md:flex hidden"></div>

            <form className="card-form mt-10">
                <span>
                    <label htmlFor="">Amount ( &#8358; )</label>
                    <input type="text" onChange={(ev) => link.mode === "Custom" && amount === "" ? setAmount(ev.target.value) : false} value={amount} contentEditable={link.mode === "Custom" && amount === "" ? true : false} placeholder='Amount' />
                </span>
                <span>
                    <label htmlFor="">Bank</label>
                    <select className='h-12 rounded-full bg-transparent border-[1px] px-5 outline-none focus:outline-primary border-neutral-600' onChange={(ev) => {
                        setCode(ev.target.value)
                    }} >
                        {codes.map(co => <option value={co.code}>{co.bank}</option>)}
                    </select>
                </span>

                <div className='expiry-date-cvv'>
                    {ussd ?
                        <>
                            <span>
                                <label htmlFor="">Code</label>
                                <input value={ussd} />
                                {/* <button onClick={(ev) => {
                            ev.preventDefault()
                            navigator.clipboard.writeText(ussd)
                        }}> Copy</button> */}
                            </span>


                        </>
                        :
                        <span>
                            <label htmlFor="">Email:</label>
                            <input type="text" onChange={(ev) => setEmail(ev.target.value)} value={email} placeholder='email' />
                        </span>
                    }

                </div>

                {ussd &&
                    <div className='expiry-date-cvv'>

                        <div className="info-cta-btn">
                            <button onClick={() => navigator.clipboard.writeText(ussd)}>Copy Code</button>
                        </div>

                        <div className="info-cta-btn">
                            <button onClick={() => paid()}>Paid</button>
                        </div>
                    </div>
                }

                {page === "loading" &&
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto"
                    }} >
                        <ClipLoader
                            color={"#DB831B"}
                            loading={true}
                            // cssOverride={override}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }

                {/* <div>
                    <p className='mb-10'>Powered By: <span>{user?.payment_options?.ussd ? user.payment_options.ussd : "Kudipal"}</span></p>
                </div> */}
            </form>
        </section>
    );
};

export default ViaUssd;
